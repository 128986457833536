/* Tailwind css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* global css */
body {
  @apply text-base leading-normal text-black;
}

/* OneTrust customisation */
#onetrust-consent-sdk {
  z-index: 99;
  position: relative;
}

#onetrust-banner-sdk *,
#onetrust-consent-sdk * {
  font-family:
    'Lato',
    ui-sans-serif,
    system-ui,
    -apple-system,
    sans-serif !important;
}

/*  Update ZenDesk Widget positionning */
#launcher {
  z-index: 98 !important;
}

/*  Set Design System Modal to max-height if open within another modal */
@media screen and (max-width: 600px) {
  [data-state='open']:has(> [role='dialog'])
    ~ [data-state='open']:has(> [role='dialog'])
    > [role='dialog'] {
    max-height: calc(100dvh - 24px);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }
}
