.react-datepicker__aria-live {
  @apply hidden;
}

.react-datepicker__header {
  @apply mx-3 border-b border-grey-200;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col px-3 pb-4;
}

.react-datepicker__week {
  @apply flex justify-around text-center text-base;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center text-base;
}

.react-datepicker__day-name {
  @apply flex aspect-square grow items-center justify-center;
}

.react-datepicker__navigation {
  @apply absolute top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-center justify-center rounded transition hover:bg-grey-200;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-center justify-center rounded transition hover:bg-grey-200;
}

.react-datepicker__day {
  @apply m-1 flex aspect-square h-full w-full cursor-pointer items-center justify-center rounded-full text-sm font-bold leading-none;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply pointer-events-none opacity-0;
}

.react-datepicker__day:not(.react-datepicker__day--selected):hover {
  @apply bg-grey-100;
}

.react-datepicker__day--selected:not(.react-datepicker__day--outside-month) {
  @apply bg-primary text-white;
}
